<template>
  <div>
    <div v-show="loadingSohw" class="loading">
      <van-loading type="spinner" />
    </div>
    <div v-if="isShow">
      <div v-if="isFish">
        <div class="imgBox">
          <img src="../../assets/success.png" />
        </div>
        <div class="tip">人脸认证已完成</div>
        <div class="submit-btn" @click="endEvent">
          <span>确认</span>
        </div>
      </div>
      <div class="face-box" id="face" v-else>
        <!-- <button id="start" style="margin-top: 20px" @click="startEvent">
          开始
        </button>
        <button id="stop" style="margin-top: 20px" @click="endEvent">
          停止
        </button>
        <div style="margin-top: 20px">
          <span>当前状态：</span>
          <span id="status"></span>
        </div>
        <div style="margin-top: 20px">
          <span>颜色：</span>
          <span id="color"></span>
        </div> -->
        <div>
          <!-- <span>提示语：</span> -->
          <span id="tip"></span>
        </div>
        <div id="videoParent"></div>
      </div>
    </div>
  </div>
</template>

<script>
import rtcsdk from "../../../public/rtcsdk.min";
import wx from "weixin-js-sdk";
import { Toast } from "vant";
export default {
  data() {
    return {
      rtcVideo: null,
      token: "",
      isShow: false,
      isFish: false,
      loadingSohw: true,
    };
  },
  created() {
    const queryParams = this.getQueryParams();
    console.log(queryParams, 666666666); // {param1: "value1", param2: "value2"}
    this.token = queryParams.token;
    if (this.token) {
      this.loadingSohw = false;
      this.isShow = true;
      setTimeout(() => {
        this.startEvent();
      }, 1000);
    }
  },
  mounted() {
    // // 监听返回 (安卓不生效)
    // this.pushHistory();
    // window.addEventListener("popstate", this.endEvent, false);
  },
  // 页面销毁时，清除返回监听事件 (安卓不生效)
  // beforeDestroy() {
  //   window.removeEventListener("popstate", this.endEvent, false);
  // },
  methods: {
    // // 插入浏览器历史      (安卓不生效)
    // pushHistory(str = "title", url = "#/detail") {
    //   const state = {
    //     title: str,
    //     url,
    //   };
    //   window.history.pushState(state, state.title, state.url);
    // },
    getQueryParams() {
      let url = window.location.href;
      console.log(url);
      const queryStart = url.indexOf("?");
      if (queryStart === -1) {
        return {}; // 没有查询参数
      }
      const queryStr = url.slice(queryStart + 1);
      const params = {};
      const pairs = queryStr.split("&");
      pairs.forEach((pair) => {
        const [key, value] = pair.split("=");
        params[decodeURIComponent(key)] = decodeURIComponent(value || "");
      });
      return params;
    },
    startEvent() {
      console.log(5555);
      this.rtcVideo = new rtcsdk({
        videoWrapper: "videoParent", // 页面中镶嵌video元素的位置
        width: "480px",
        height: "480px",
        token: this.token, // 用户服务器端请求getToken返回的token
        colorListener: (color, status, index, sum) => {
          console.log(color, status, index, sum);
          // if (status == "PROCESS") {
          //   document.getElementById(
          //     "color"
          //   ).innerText = `当前颜色为第${index}: ${color}, 共${sum}个，状态为${status}`;
          // } else {
          //   document.getElementById("color").innerText = `状态为${status}`;
          // }
          // 传递color给用户，用户监听color变化，修改页面背景颜色
          document.getElementById("face").style.backgroundColor = color;
        },
        tipListener: (tipCode, tipTxt) => {
          console.log(tipCode, tipTxt);
          document.getElementById("tip").innerText = tipTxt;
        }, // 提示语信息
        statusListener: (data) => {
          // document.getElementById("status").innerText = data;
          // Toast({
          //   message: data,
          // });
        }, // 状态的改变，需要调用方对各个状态做相应处理
        resultCallback: (isSuccess, code, message) => {
          console.log(this.token);
          // Toast({
          //   message: message,
          // });
          if (code === 1000) {
            this.isFish = true;
          }
          if (code === 2000) {
            Toast({
              message: "认证失败，请检查网络是否连接",
            });
            setTimeout(() => {
              this.endEvent();
            }, 3000);
          }
          if (code === 3000) {
            Toast({
              message: "认证超时，请重试",
            });
            setTimeout(() => {
              this.endEvent();
            }, 3000);
          }
          if (code === 4000) {
            Toast({
              message: "当前设备不支持认证！",
            });
            setTimeout(() => {
              this.endEvent();
            }, 3000);
          }
          if (code === 5000) {
            Toast({
              message: "认证超时或参数不合法，请重试",
            });
            setTimeout(() => {
              this.endEvent();
            }, 3000);
          }
          // 终态
          console.log(
            new Date().getTime(),
            "resultCallback:",
            "是否成功:",
            isSuccess,
            "状态码:",
            code,
            "信息:",
            message
          );
        },
      });
      this.rtcVideo.start();
    },
    endEvent() {
      this.rtcVideo && this.rtcVideo.destroy();
      console.log(7879798, "退出");
      // let value1 = "回传数据1";
      // const message = {
      //   value1: value1,
      //   token: this.token,
      // };
      wx.miniProgram.navigateBack({ delta: 1 }); //返回
      // wx.miniProgram.postMessage({ data: message }, "*"); //带入数值
      // wx.miniProgram.navigateTo({
      //   url: `/pages/qbhome/index?param1=${value1}&token=${value2}`,
      // });
    },
  },
};
</script>

<style scoped lang="less">
.box {
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #f5f5f5;
}
.loading {
  width: 100%;
  min-height: 100vh;
  background: #eee;
  /deep/.van-loading {
    left: 50%;
    top: 52%;
    width: 100%;
    height: 100%;
    padding-top: 450px;
  }
}

.face-box {
  text-align: center;
  width: 100%;
  min-height: 100vh;
  padding-top: 120px;
}

#videoParent {
  width: 480px;
  height: 480px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #ccc;
  align-items: center;
  margin-top: 6%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}
#tips {
  text-align: center;
  margin-top: 20px;
}

.imgBox {
  margin: 158px 320px 48px 320px;
  width: 120px;
  height: 120px;
  > img {
    width: 100%;
    height: 100%;
  }
}
.tip {
  color: #333333;
  font-size: 28px;
  text-align: center;
}
.submit-btn {
  width: 620px;
  height: 90px;
  line-height: 90px;
  text-align: center;
  background: #ef3535;
  border-radius: 45px;
  color: #ffffff;
  font-size: 32px;
  font-weight: 400;
  margin: 159px 65px 0 65px;
}
</style>
